var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app",
    [
      _c("app-navbar"),
      _c("app-sidebar"),
      _c(
        "v-content",
        { staticClass: "secondary-background" },
        [
          _c(
            "v-container",
            { staticClass: "px-lg-5", attrs: { fluid: "" } },
            [
              _c(
                "v-app-bar",
                {
                  staticClass: "p-0 m-0",
                  attrs: { prominent: "", dense: "", flat: "" },
                  scopedSlots: _vm._u([
                    {
                      key: "extension",
                      fn: function() {
                        return [
                          _c(
                            "v-tabs",
                            {
                              staticClass: "white--text headline",
                              attrs: {
                                "align-with-title": "",
                                "center-active": "",
                                "show-arrows": ""
                              },
                              model: {
                                value: _vm.tab,
                                callback: function($$v) {
                                  _vm.tab = $$v
                                },
                                expression: "tab"
                              }
                            },
                            _vm._l(_vm.menu, function(item) {
                              return _c(
                                "v-tab",
                                { key: item.to, attrs: { to: item.link } },
                                [_vm._v(_vm._s(_vm.$t(item.title)))]
                              )
                            }),
                            1
                          )
                        ]
                      },
                      proxy: true
                    }
                  ])
                },
                [
                  _c(
                    "v-toolbar-title",
                    { staticClass: "mx-6 display-1" },
                    [
                      _c("app-avatar", {
                        attrs: {
                          url: _vm.getFileUrl(_vm.user.avatar),
                          alternative: _vm.user.initials,
                          size: "medium"
                        }
                      }),
                      _vm._v(" " + _vm._s(_vm.user.name) + " ")
                    ],
                    1
                  )
                ],
                1
              ),
              _vm.dataLoaded
                ? _c(
                    "v-row",
                    {
                      attrs: {
                        "no-gutters": "",
                        "align-content": "center",
                        justify: "center"
                      }
                    },
                    [
                      _c(
                        "v-card",
                        {
                          staticClass: "fill-height my-5 mx-5",
                          attrs: { outlined: "", flat: "", width: "100%" }
                        },
                        [
                          _c("v-card-title", {
                            staticClass: "justify-center text-center",
                            domProps: { textContent: _vm._s(_vm.cardTitle) }
                          }),
                          _c("v-card-subtitle", {
                            staticClass: "justify-center text-center",
                            domProps: { textContent: _vm._s(_vm.cardSubtitle) }
                          }),
                          _c("router-view", { staticClass: "page-wrapper" })
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }